<template>
    <b-card class="soft mb-5">
        <ValidationObserver ref="formApprovalStatus">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex flex-row justify-content-between mb-2">
                        <span>{{ $t('status') }}</span>
                        <div class="d-flex flex-row">
                            <b-button v-if="avaibleEdit" class="mr-3" variant="outline-primary"
                                @click="() => this.formDisabled = false">
                                {{ `${$t('edit')}` }}
                            </b-button>
                            <b-button variant="outline-danger" @click="$emit('delete', index)">
                                {{ `${$t('delete')}` }}
                            </b-button>
                        </div>
                    </div>
                </b-col>
                <template v-if="formDisabled">
                    <b-col cols="12" md="6" class="py-1">{{ $t('key') }}: {{ innerValue.key }}</b-col>
                    <b-col cols="12" md="6" class="py-1">{{ $t('name') }}: {{ innerValue.name }}</b-col>
                    <b-col cols="12" md="6" class="py-1">{{ $t('name_en') }}: {{ innerValue.name_en }}</b-col>
                    <b-col cols="12" md="6" class="py-1">{{ $t('value') }}: {{ innerValue.status }}</b-col>
                </template>
                <template v-else>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('key')">
                            <ValidationProvider name="key" rules="required" v-slot="{ valid, errors }">
                                <b-form-input v-model="innerValue.key" :state="errors[0] ? false : null"
                                    :disabled="formDisabled" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('name')">
                            <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                <b-form-input v-model="innerValue.name" :state="errors[0] ? false : null"
                                    :disabled="formDisabled" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('name_en')">
                            <ValidationProvider name="name_en" rules="required" v-slot="{ valid, errors }">
                                <b-form-input v-model="innerValue.name_en" :state="errors[0] ? false : null"
                                    :disabled="formDisabled" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('value')">
                            <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                                <b-form-input v-model="innerValue.status" :state="errors[0] ? false : null"
                                    :disabled="formDisabled" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <div class="d-flex justify-content-start">
                            <b-button variant="primary" @click="addApproveStatus">
                                {{ `${$t('save')}` }}
                            </b-button>
                        </div>
                    </b-col>
                </template>
            </b-row>
        </ValidationObserver>
    </b-card>
</template>

<script>
import handleInput from "@/helpers/handleInput";

import { ApprovalStepStatusType } from "../type/ApprovalStepType"

export default {
    name: "ApprovalStatuse",
    components: {},
    props: {
        formType: {
            type: String,
            default: ""
        },
        formId: {
            type: Number,
            default: null
        },
        value: {
            type: Object,
            default: () => new ApprovalStepStatusType(),
        },
        index: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            formDisabled: false
        }
    },
    watch: {},
    computed: {
        approverTypeVal() {
            return this.innerValue.approver_type?.value
        },
        avaibleEdit() {
            return !["delete", "show"].includes(this.formType) && this.formDisabled
        },
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("updateValue", val);
            },
        },
        okButtonText() {
            switch (this.formType) {
                case "create":
                    return this.$t('create')
                case "show":
                    return this.$t('close')
                case "update":
                    return this.$t('update')
                case "delete":
                    return this.$t('delete')
                default:
                    return this.$t('close')
            }
        }
    },
    methods: {
        handleInput,
        async addApproveStatus() {
            let isValid = await this.$refs.formApprovalStatus.validate()
            if (!isValid) {
                return
            }
            this.formDisabled = true
            // this.$emit("save", this.innerValue, this.index)
        }
    },
    created() {
        this.formDisabled = ["delete", "show", "update"].includes(this.formType)
    }
}
</script>
